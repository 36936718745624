import DefaultErrorPage from 'next/error';

import { isBuilderPreviewing, useGetBuilderPageQuery } from '@arrived/builder.io';
import { Blog, DefaultBlogQueryParams } from '@arrived/features-blog/routes';
import { SEO, useRouter } from '@arrived/router';

import { SubNavLayout } from '../../components/SubNavLayout';

export { getStaticProps } from '@arrived/features-blog/routes';

const NextBlog = () => {
  const router = useRouter();
  const pageState = useGetBuilderPageQuery(DefaultBlogQueryParams);

  const canShowContent = pageState.data?.data || isBuilderPreviewing(router.asPath);

  if (!canShowContent) {
    return <DefaultErrorPage statusCode={404} />;
  }

  return (
    <>
      <SEO>
        <SEO.Title content="Blog" />
        <SEO.Description content={pageState.data?.data?.description} />
      </SEO>
      <Blog />
    </>
  );
};

NextBlog.getLayout = SubNavLayout;
NextBlog.hasFooter = true;

export default NextBlog;
