import { useMemo } from 'react';

import type { GetStaticProps } from 'next';

import { FormattedMessage } from 'react-intl';
import Animated, { FadeInDown, FadeOut } from 'react-native-reanimated';

import { FlashList } from '@shopify/flash-list';
import { QueryClient, dehydrate } from '@tanstack/react-query';

import {
  AnimatePresence,
  BlurView,
  Divider,
  Loading,
  ScrollView,
  Stack,
  TitleText,
  getTokens,
  getVariableValue,
  isWeb,
} from '@arrived/bricks';
import { RefreshControl } from '@arrived/bricks-common';
import {
  ArrivedBuilderModels,
  ArrivedBuilderSymbols,
  fetchBuilderPage,
  fetchBuilderSymbol,
  isBuilderPreviewing,
  useGetBuilderPageQuery,
  useGetBuilderSymbolQuery,
} from '@arrived/builder.io';
import { Link, useLink, useRouter } from '@arrived/router';

import { BlogBuilderContent } from '../BlogBuilderContent';
import {
  ArticleCard,
  ArticleList,
  ArticleListPagination,
  ArticleTitleMetadata,
  BlogContainerBar,
  CategoriesNavigation,
  CategorySlugHeaderCard,
  SearchButton,
  useCategoriesNavigationContext,
} from '../components';
import {
  UseGetBlogArticleQueryResult,
  builderCategoriesSymbolQueryKey,
  builderPageQueryFn,
  builderRecentArticlesKeyFn,
  builderSymbolsQueryKeyFn,
  fetchRecentArticles,
  useGetArticlesByCategoryQuery,
  useGetCategoryQuery,
  useGetRecentArticlesQuery,
} from '../queries';
import { fetchCategories } from '../queries/useGetCategoriesQuery';

/**
 * Default query params for the `/blog` page,
 * this was made as we use it in multiple spots
 * to hydrate this page.
 * @see /apps/public-app/pages/blog/index.tsx
 */
export const DefaultBlogQueryParams = {
  urlPath: '/blog',
  enrich: true,
};

/**
 * Another default config, I should find a better way to do this...
 */
export const DefaultRecentArticlesQueryParams = {
  sort: {
    createdDate: -1,
  },
  enrich: true,
} as const;

export const getStaticProps: GetStaticProps = async () => {
  const queryClient = new QueryClient();

  await queryClient.prefetchQuery({
    queryKey: builderPageQueryFn(DefaultBlogQueryParams),
    queryFn: () => fetchBuilderPage(DefaultBlogQueryParams),
  });

  await queryClient.prefetchQuery({
    queryKey: builderRecentArticlesKeyFn(DefaultRecentArticlesQueryParams),
    queryFn: () => fetchRecentArticles(DefaultRecentArticlesQueryParams),
  });

  await queryClient.prefetchQuery({
    queryKey: builderSymbolsQueryKeyFn({
      symbolId: ArrivedBuilderSymbols.WEBINAR,
    }),
    queryFn: () =>
      fetchBuilderSymbol({
        symbolId: ArrivedBuilderSymbols.WEBINAR,
      }),
  });

  await queryClient.prefetchQuery({
    queryKey: builderCategoriesSymbolQueryKey(),
    queryFn: () => fetchCategories(),
  });

  return {
    props: { dehydratedState: dehydrate(queryClient) },
    revalidate: 20,
  };
};

const AnimatedStack = Animated.createAnimatedComponent(Stack);

const RecentPostArticle = ({ post }: { post: NonNullable<UseGetBlogArticleQueryResult['data']> }) => {
  // I'm asserting here because we do the null check in the RecentPostList component
  const linkHrefProps = useLink({
    href: `/blog/${post.data!.slug}`,
  });

  return (
    <ArticleCard gap="$2" {...linkHrefProps}>
      {post.data!.featuredImage && (
        <ArticleCard.Image title={post.data!.title} featuredImage={post.data!.featuredImage} flexShrink={1} />
      )}

      <Stack flex={1} gap="$2">
        <ArticleCard.Header tag="h3" flex={1}>
          {post.data!.title}
        </ArticleCard.Header>

        <ArticleTitleMetadata post={post} />
      </Stack>
    </ArticleCard>
  );
};

const RecentPostList = () => {
  const recentPostsState = useGetRecentArticlesQuery(DefaultRecentArticlesQueryParams);

  return (
    <ArticleList>
      <ArticleList.Header>
        <ArticleList.Header.Title tag="h3">
          <FormattedMessage id="blog.blog-page.recent-articles.title" />
        </ArticleList.Header.Title>
      </ArticleList.Header>
      <ArticleList.Body>
        <ArticleList.Body.Items $gtXxs={{ row: true }}>
          {recentPostsState.data?.map((post, index) => {
            if (!post?.data) {
              return null;
            }

            return <RecentPostArticle key={`recent-article-${index}-${post.id}`} post={post} />;
          })}
        </ArticleList.Body.Items>
      </ArticleList.Body>
    </ArticleList>
  );
};

const sizeTokens = getTokens().size;

/**
 * This is used to render the blog content on native where
 * we are using state to manage the categories instead of
 * React Navigation as it provides a smoother experience.
 */
const NativeBlogContent = () => {
  const { activeCategorySlug, page, setPage } = useCategoriesNavigationContext();
  const pageState = useGetBuilderPageQuery(DefaultBlogQueryParams);
  const webinarSymbolState = useGetBuilderSymbolQuery({
    symbolId: ArrivedBuilderSymbols.WEBINAR,
  });

  const categorySymbolState = useGetCategoryQuery({ categorySlug: activeCategorySlug });
  const categoryPostsState = useGetArticlesByCategoryQuery({
    categoryIds: categorySymbolState.data?.id ? [categorySymbolState.data.id] : [],
    pageParam: page,
  });

  const listData = useMemo(() => {
    if (activeCategorySlug === 'blog') {
      return [];
    }

    return categoryPostsState.data;
  }, [activeCategorySlug, categoryPostsState.data]);

  return (
    <>
      <AnimatedStack
        key={`${activeCategorySlug}-${page}`}
        entering={FadeInDown.delay(450)}
        exiting={FadeOut}
        flexGrow={1}
      >
        {categoryPostsState.isPending && (
          <AnimatedStack
            key={activeCategorySlug}
            entering={FadeInDown.delay(450)}
            exiting={FadeOut}
            fullscreen
            centered
            flexGrow={1}
            bg="white"
            zIndex="$overlay"
          >
            <Loading.Indicator variant="colored" />
          </AnimatedStack>
        )}

        <FlashList
          data={listData}
          contentContainerStyle={{
            padding: getVariableValue(sizeTokens.$4),
          }}
          estimatedItemSize={350}
          refreshControl={<RefreshControl onRefresh={categoryPostsState.refetch} />}
          keyExtractor={(item) => item.id}
          ListHeaderComponent={
            <Stack pb="$4">
              {categorySymbolState.data?.data && <CategorySlugHeaderCard {...categorySymbolState.data.data} />}
            </Stack>
          }
          ListFooterComponent={
            <Stack gap="$8">
              {activeCategorySlug === 'blog' && (
                <BlogBuilderContent model={ArrivedBuilderModels.PAGE} content={pageState.data} enrich />
              )}

              <BlogBuilderContent model={ArrivedBuilderModels.SYMBOL} content={webinarSymbolState.data} enrich />
              <RecentPostList />
            </Stack>
          }
          ListEmptyComponent={
            <Stack>
              {activeCategorySlug !== 'blog' && (
                <AnimatedStack
                  key={activeCategorySlug}
                  entering={FadeInDown.delay(450)}
                  exiting={FadeOut}
                  centered
                  flexGrow={1}
                  minHeight={300}
                  bg="white"
                  zIndex="$overlay"
                >
                  <Loading.Indicator variant="colored" />
                </AnimatedStack>
              )}
            </Stack>
          }
          ListFooterComponentStyle={{
            paddingTop: getVariableValue(sizeTokens.$4),
          }}
          renderItem={({ item, index }) => (
            <Link href={`/blog/${item.data.slug}`}>
              <ArticleCard
                $platform-native={{
                  pointerEvents: 'none',
                }}
              >
                {item.data?.featuredImage && (
                  <ArticleCard.Image
                    key={`${item.data.featuredImage}-${index}`}
                    title={item.data.title}
                    featuredImage={item.data.featuredImage}
                  />
                )}
                <ArticleCard.Header tag="h4">{item.data.title}</ArticleCard.Header>
                <ArticleCard.Excerpt>{item.data.excerpt}</ArticleCard.Excerpt>
              </ArticleCard>
            </Link>
          )}
          ItemSeparatorComponent={() => <Divider solid alt py="$4" />}
        />
      </AnimatedStack>
      {activeCategorySlug !== 'blog' && !categoryPostsState.isPending && (
        <Stack py="$2">
          <ArticleListPagination
            categoryPostsState={categoryPostsState}
            categorySlug={activeCategorySlug}
            pageParam={page}
            setPage={setPage}
          />
        </Stack>
      )}
    </>
  );
};

export const Blog = () => {
  const router = useRouter();
  const pageState = useGetBuilderPageQuery(DefaultBlogQueryParams);
  const webinarSymbolState = useGetBuilderSymbolQuery({
    symbolId: ArrivedBuilderSymbols.WEBINAR,
  });

  const canShowContent = isBuilderPreviewing(router.asPath) || pageState.isPending || pageState.isSuccess;

  if (!canShowContent) {
    return (
      <Stack centered flexGrow={1}>
        <TitleText>Content Not Found</TitleText>
      </Stack>
    );
  }

  return (
    <>
      <AnimatePresence>
        {pageState.isPending && (
          <Loading key="loading" fullscreen centered zIndex="$overlay">
            <BlurView fullscreen intensity={20} />
            <Loading.Indicator variant="colored" />
          </Loading>
        )}
      </AnimatePresence>
      <BlogContainerBar>
        <Stack row alignItems="center" justifyContent="space-between" gap="$2">
          <CategoriesNavigation />

          <SearchButton />
        </Stack>
      </BlogContainerBar>

      {isWeb ? (
        <ScrollView width="100%" maxWidth={1200} mx="auto">
          <Stack gap="$8" py="$4" px="$4" $gtXxs={{ py: '$8' }}>
            <BlogBuilderContent model={ArrivedBuilderModels.PAGE} content={pageState.data} enrich />

            <BlogBuilderContent model={ArrivedBuilderModels.SYMBOL} content={webinarSymbolState.data} enrich />

            <RecentPostList />
          </Stack>
        </ScrollView>
      ) : (
        <NativeBlogContent />
      )}
    </>
  );
};
