import { ReactNode } from 'react';

import { Container } from '@arrived/bricks';

export const SubNavLayout = (page: ReactNode) => {
  return (
    <Container $gtSm={{ p: 0 }}>
      {/* When the container needs a bit of space from the footer on larger screens */}
      <Container.Inner $gtMd={{ mb: '$4' }}>{page}</Container.Inner>
    </Container>
  );
};
