import { EssentialBuilderContent, EssentialBuilderContentProps, RegisteredComponent } from '@arrived/builder.io';

import {
  ArticleCategoriesList,
  ArticleContent,
  ArticlePreview,
  ArticlePreviewLink,
  ArticleSection,
  BrowsePropertiesCTA,
  CategoriesSearch,
  DisclaimerSection,
  Divider,
  FAQSection,
  FeaturedArticle,
  RelatedArticles,
  WebinarBlogWidget,
} from './components';

/**
 * This is the equivalent of Builders `registerComponent` function,
 * we are doing this as of their v2 release as they deprecated the `registerComponent` function.
 * https://github.com/BuilderIO/builder/blob/4fd24d4d917e218646623ad3b59e59410b42428d/examples/react-native/App.jsx
 */
const RegisteredComponents = [
  // Display Components
  ArticleContent.registerComponent,
  ArticleSection.registerComponent,
  ArticleCategoriesList.registerComponent,
  ArticlePreviewLink.registerComponent,
  ArticlePreview.registerComponent,
  RelatedArticles.registerComponent,
  BrowsePropertiesCTA.registerComponent,
  CategoriesSearch.registerComponent,
  DisclaimerSection.registerComponent,
  Divider.registerComponent,
  FAQSection.registerComponent,
  FeaturedArticle.registerComponent,

  // Webinar Components
  WebinarBlogWidget.registerComponent,
] satisfies RegisteredComponent[];

/**
 * This component wraps the Builder.io Content component with
 * our API key and custom blog components that are registered.
 */
export const BlogBuilderContent = (props: EssentialBuilderContentProps) => (
  <EssentialBuilderContent customComponents={RegisteredComponents} {...props} />
);
